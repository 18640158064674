import React from 'react';
import styled from 'styled-components';
import Layout from '../../components/layout';

import MediplexImage from '../../img/mediplex.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  width: 100%;
  text-align: center;
  min-height: 700px;

  @media (min-width: 768px) {
    flex-wrap: wrap;
  }
`;

const CommunityImage = styled.img`
  margin-bottom: 2rem;
  max-width: 500px;
`;

export default function Mediplex() {
  return (
    <Layout>
      <Container>
        <h2>Community</h2>
        <p>
          <em>
            Throughout the year, members of Abraham H. Howland Jr. Lodge take
            time to provide care and resources to our community. When we come
            together for this common purpose--to help others--we form a special
            bond among ourselves and our community.
          </em>
        </p>

        <h3 id="mediplex">Mediplex</h3>
        <p>
          For several years, members of Abraham H. Howland Jr. Lodge made it
          possible for the patients of the Mediplex facility to have a Merry
          Christmas with a little special help from Red Sox pitcher Brian Rose.
        </p>
        <CommunityImage alt="mediplex" src={MediplexImage} />
      </Container>
    </Layout>
  );
}
